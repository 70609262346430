<style scoped>
.top-tab {
  width: 100%;
  min-width: 1320px;
  height: 50px;
  background-color: #515c76;
  z-index: 999;
  opacity: 0.8;
  margin: -50px auto 0;
}

.tabInner {
  width: 1320px;
  padding: 0 315px;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  margin: 0 auto;
}

.tabInner > div {
  cursor: pointer;
}

.tab-active {
  position: relative;
}

.tab-active::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 50%;
  width: 30px;
  height: 2px;
  background: #fff;
  transform: translateX(-50%);
}

/*  */
.content {
  width: 100%;
  min-width: 1320px;
  max-width: 2000px;
  height: 480px;
  margin: 51px auto 0;
  overflow-x: scroll;
  overflow: hidden;
  position: relative;
}
.con-item {
  height: 100%;
  min-width: calc(100% / 3);
  position: relative;
}

.con-item:hover .posi-back {
  background: none;
  opacity: 0;
}

.con-posi {
  position: absolute;
}

.posi-back {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 2;
}

.con-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: calc(100% - 49 * 2px);
  color: #fff;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}

.text-p1 {
  font-size: 32px;
  width: 100%;
}

.text-p2 {
  font-size: 18px;
  letter-spacing: 5px;
  color: #f1f4f8;
}

.text-p3 {
  font-size: 15px;
}

.con-div {
  width: 80px;
  height: 2px;
  background: #fff;
  margin: 20px 0;
}

.con-div2 {
  width: 100%;
}

.con-div2 > p {
  display: inline;
}

.con-item > img {
  width: 100%;
  height: 100%;
}

/*  */
.text-1 {
  text-align: center;
  font-size: 45px;
  color: #333;
  font-weight: bold;
  letter-spacing: 5px;
  margin-top: 93px;
}
.title-inner > span {
  text-align: center;
  color: #d6d6d6;
  font-size: 16px;
  letter-spacing: 20px;
  margin: 15px 0 78px;
}
.title-inner > span:first-child {
  margin-right: 38px;
}
.no-data {
  text-align: center;
  margin: 50px;
  color: #999;
}
.el-carousel {
  width: 100%;
  position: relative;
}
.el-carousel__item img{
  width: 100%;
  height: 100%;
}
/deep/.el-carousel__indicators{
    display: none;
}
.cons-list{
  position: absolute;
 bottom: 50px;
 padding: 0px 50px;
}
.cons-list span{
  /* display: block; */
  color: #fff;
  line-height: 30px;
  
}
.cons-list>div{
  width: 50px;
  height: 2px;
  background: #cccc;
}
</style>

<template>
  <div>
    <Top :topTitle="topTitle"></Top>
    <div class="top-tab">
      <div class="tabInner flex-around flex-y-center">
        <div
          :class="{ 'tab-active': tabIndex == index }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="toChange(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content flex" v-if="product.length > 0">
      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item v-for="(item,index) in product" :key="index">
          <div @click="toPath(item.id)">
            <img :src="item.image" />
            <div class="cons-list">
              <span>{{ item.store_name }}</span>
              <div></div>
              <span>{{ item.title }}</span>
              <span>({{ item.introduction }})</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="no-data" v-else>暂无数据~~~</div>
    <div class="title-box">
      <p class="text-1">经典案例</p>
      <div class="title-inner flex-x-center">
        <span>GOOD</span>
        <span>CASE</span>
      </div>
    </div>
    <div class="content flex" v-if="product1.length > 0" >
      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item v-for="(item , index) in product1" :key="index" >
          <div @click="toPath(item.id)">
            <img :src="item.image" />
            <div class="cons-list">
              <span>{{ item.store_name }}</span>
              <div></div>
              <span>{{ item.title }}</span>
              <span>({{ item.introduction }})</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="no-data" v-else>暂无数据~~~</div>
    <Bottom :minWidth="minWidth"></Bottom>
  </div>
</template>

<script>
import Top from "../../components/top.vue";
import Bottom from "../../components/bottom.vue";
export default {
  data() {
    return {

      nowIndex: 0, // 当前展示的图片
        flag: true, // 节流阀，防止连续点击切换
      topTitle: "合作客户",
      minWidth: 1320,
      tabList: [
        {
          name: "全部",
          id: 0,
        },
      ],
      tabIndex: 0,
      page: 1,
      lastPage: 1,
      product: [],
      page1: 1,
      lastPage1: 1,
      product1: [],
      // backImg: require("../assets/images/partner_02.jpg"),
      backImg2: require("../assets/images/partner_03.png"),
    };
  },

  created() {
    this.getCate();
    this.getProduct(0);
	this.getProduct1(0);
  },
  methods: {
    // productScroll(e) {
    //   if (
    //     e.srcElement.scrollWidth - e.srcElement.scrollLeft <=
    //     document.documentElement.clientWidth + 100
    //   ) {
    //     if (this.page < this.lastPage) {
    //       this.page++;
    //       this.getProduct(this.tabList[this.tabIndex].id);
    //     }
    //   }
    // },
    getCate() {
      this.http({
        method: "get",
        url: this.api.getServer,
        data: {},
      }).then((res) => {
        var list = this.tabList;
        res.data.map(function (item) {
          list.push(item);
        });
        this.tabList = list;
      });
    },
    getProduct(cate_id) {
      this.http({
        method: "get",
        url: this.api.getProductLIst,
        data: {
          page: this.page,
          cate_id,
          pageSize: 10,
        },
      }).then((res) => {
        this.lastPage = res.data.last_page;
        if (this.page == 1) {
          this.product = [];
        }
        var product = this.product;
        if (product.length) {
          res.data.data.map(function (item) {
            product.push(item);
          });
        } else {
          product = res.data.data;
        }
        this.product = product;
      });
    },
    //经典案例
    getProduct1(cate_id) {
      this.http({
        method: "get",
        url: this.api.getProductLIst,
        data: {
          page: this.page1,
          cate_id,
          is_classic: 1,
          pageSize: 10,
        },
      }).then((res) => {
        this.lastPage1 = res.data.last_page;
        if (this.page1 == 1) {
          this.product1 = [];
        }
        var product = this.product1;
        if (product.length) {
          res.data.data.map(function (item) {
            product.push(item);
          });
        } else {
          product = res.data.data;
        }
        this.product1 = product;
      });
    },
    toChange(index) {
      this.tabIndex = index;
      var id = this.tabList[index].id;
      this.page = 1;
      this.page1 = 1;
      this.getProduct(id);
      this.getProduct1(id);
    },
    toPath(id) {
      this.$router.push("/partnerDetail?id=" + id);
    },
  },
  components: {
    Top,
    Bottom,
  },
};
</script>
